<template>
  <section class="m-login m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img src="@/assets/login/logo2@2x.png" alt="img" class="logo">
      <p class="tips">Verifications</p>
    </div>

    <div class="main code">
        <p>已向您发送6位数的验证码，请在下面输入</p>
        <p>验证码</p>
        <div class="input-box" v-if="isCode" @click="handleFocus()">
            <input ref="input" type="number" v-model="code" @focus="focusHandle" @blur="blurHandle">
            <div class="input-view">{{code}}</div>
            <div class="input-line">
                <span v-for="i in 6" :key="i" :class="{'input-active': (code.length >= i) && isFocus}"></span>
            </div>
        </div>
        <van-button type="default" v-else @click="sendPhone($route.query.phone)" class="regetcode">重新获取</van-button>
    </div>
  </section>
</template>

<script>

import { phone_code, phone_login, email_code, email_login } from '@/api/zh/login'
import { getAccountInfo } from "@/api/zh/mine";
import { getCoupon } from "@/api/en/night_market.js";
export default {
  name:'Code',
  data() {
    return {
      code: '',
      isFocus: false,
      isCode: true
    }
  },

  created(){
    this.$store.commit('load')
    if(this.$route.query.phone) {
      this.sendPhone(this.$route.query.phone)
    }
    if(this.$route.query.email) {
      this.sendEmail(this.$route.query.email)
    }
  },

  methods:{
        focusHandle() {
            // 输入框聚焦
            this.isFocus = true
        },
        blurHandle() {
            // 输入框聚焦
            this.isFocus = false
        },

        // 发送手机验证码
        sendPhone(phone) {
          let params = { 
              phone    : phone,
              area_code: phone.length == 11 ? 86: 1,
              model    : 'login'
          }
          this.$store.commit('load')
          this.isCode = true
          phone_code(params).then(res => {
            if(res) {
              this.$store.commit('unload')
              this.$refs.input.focus()
            } else {
              this.isCode = false
              this.$store.commit('unload')
            }
          })
        },

        // 验证手机验证码
        checkPhone(code) {
          let params = { 
              phone    : this.$route.query.phone,
              area_code: this.$route.query.phone.length == 11 ? 86: 1,
              code     : code,
              night_token: '',
              is_coupon: window.localStorage.getItem("coupon") ? 1 : ''
           }
           if (localStorage.getItem('token2')) {
            params.night_token = localStorage.getItem('token2')
          }
          phone_login(params).then(res => {
            if(res) {
              localStorage.setItem('token', res.access_token)
              localStorage.setItem('token2', res.access_token)
              if (res.is_night && res.is_night == 1) {
                getCoupon();
              }
              getAccountInfo().then(res => {  
                  if(res){
                     localStorage.setItem('setPersonal',JSON.stringify(res.data))
                     
                     if(this.$route.query.cart == 1) {
                       this.$router.push('/zh/cart')
                     } else if(this.$route.query.bargain == 1) {
                       this.$router.push('/zh/bargain')
                     } else if(localStorage.getItem("pay_titleF")){
                       this.$router.push('/zh')
                     } else {
                       this.$router.go(-2)
                     }
                  }
              })
              
            }else{
              this.code = ''
              this.isCode = false
            }
          })
        },



        // 发送邮箱验证码
        sendEmail(email) {
          let params = { 
              email: email,
              model: 'login'
          }
          email_code(params).then(res => {
            if(res) {
              this.$store.commit('unload')
              this.$refs.input.focus()
            }
          })
        },

        // 验证邮箱验证码
        checkEmail(code) {
          let params = { 
              email: this.$route.query.email,
              code : code,
              night_token: '',
              is_coupon: window.localStorage.getItem("coupon") ? 1 : ''
           }
           if (localStorage.getItem('token2')) {
            params.night_token = localStorage.getItem('token2')
          }
          email_login(params).then(res => {
            if(res) {
              localStorage.setItem('token', res.access_token)
              localStorage.setItem('token2', res.access_token)
              if (res.is_night && res.is_night == 1) {
                getCoupon();
              }
              getAccountInfo().then(res => {  
                  if(res){
                     localStorage.setItem('setPersonal',JSON.stringify(res.data))
                     if(this.$route.query.cart == 1) {
                        this.$router.push('/zh/cart')
                      } else if(this.$route.query.bargain == 1) {
                        this.$router.push('/zh/bargain')
                      } else if(localStorage.getItem("pay_titleF")){
                        this.$router.push('/zh')
                      } else {
                        this.$router.go(-2)
                      }
                  }
              })
            }
          })
        },

        // 重新聚焦输入框
        handleFocus() {
          this.$refs.input.focus()
        }

  },
  watch: {
      code(val, old) {
          if(val.length === 6) {
            if(this.$route.query.phone) {
              this.checkPhone(val)
            }
            if(this.$route.query.email) {
              this.checkEmail(val)
             }
          }
          if(val.length > 6) {
              this.code = old
          }
      }
  }
}
</script>

<style lang="less" scoped>
@import './login.less';
.regetcode{width:100%;background-color: #1989fa;color:#fff;border-radius: 50px;margin-top:200px}
</style>
