import axios from 'axios'
// import router from '@/router'
import store from '@/store/index.js'
import { Notify } from 'vant'
axios.defaults.timeout = 1000000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// 环境的切换
// if (process.env.NODE_ENV == 'development') { 
//     axios.defaults.baseURL = 'https://nwapi.moobyyoho.com'
// }else{
//     axios.defaults.baseURL = 'https://nwapi.moobyyoho.com'
// }

const service = axios.create({
    baseURL: 'https://nwapi.moobyyoho.com',
    timeout: 200000
  })


//  请求拦截器
service.interceptors.request.use(
    config => {
        config.baseURL = 'https://nwapi.moobyyoho.com'
        if(localStorage.getItem('token')) {
            config.headers['WapNightFair-Access-Token'] = localStorage.getItem('token')
        }
        // delete config.headers['MoobyYoho-Lang'];
        // delete config.headers['MoobyYoho-Access-Token'];
        return config
    },
    error => { 
        store.commit('unload')
        return Promise.error(error)
    }
)
// 响应拦截器
service.interceptors.response.use(response => {  
    if (response.status === 200) {
        // 判断状态码 过滤某些信息
        if(response.data.code == 20000){
            return response;
        }else if(response.data.code == 20001){
            // let lang = router.app.$route.path.split('/')
            // if (lang[1] == "richmond" || lang[1] == "nightmarketcoupons") {
            //     lang.splice(1,1,'en')
            // }
            localStorage.removeItem('token');
            localStorage.removeItem('token2');
            store.commit('unload');
            window.location.href = 'https://m.richmondnightmarket.com/#/login?token=clear'
            // window.location.href = 'http://192.168.31.246:3000/'
            // router.push({path:`/${lang[1]}/login` })
            return  {}
        }else if(response.data.code == 20005){
            Notify({ type: 'warning', message: response.data.msg })
            return  response
        }else {
            return  response;
        }
    }
}, error => {
    return Promise.reject(error)
})

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function get(url, params){ 
    return new Promise((resolve, reject) =>{ 
        service.get(url,{ params:params }).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data) 
        }) 
    })
}


/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 * @param {Object} config [请求头设置信息] 
 */
export function post(url, params, config) {
    return new Promise((resolve, reject) => {
        service.post(url, params, config).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}



/** 
 * put方法，对应put请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        service.put(url,params).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}


/** 
 * delete方法，对应delete请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function del(url, params) {
    return new Promise((resolve, reject) => {
        service.delete(url,params).then(res => {
            resolve(res.data)
        }).catch(err =>{
            reject(err.data)
        })
    })
}
