/** 
 *   星光夜市
 */
 import { get } from '@/untils/js/NightMarket.js'

//  一键领取
 export const getCoupon = params => get("/get_coupon",params);

//  优惠券显示
 export const coupon_list = params => get("/coupon",params);

//  使用优惠券
export const use_coupon = params => get("/use_coupon",params);


//  商家优惠券详情
export const coupon_detail = params => get("/detail",params);

// 优惠券使用状态
export const use_info = params => get("/use_info",params);